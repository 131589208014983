<template>
  <div :class="['picker-loader', { 'picker-loader--divider': divider }]">
    <div class="flex-1 space-y-5 py-1">
      <div class="picker-loader__bar w-1/4" />
      <div v-if="!alt" class="picker-loader__bar" />
      <div v-else class="flex space-x-4">
        <div class="picker-loader__bar flex-1" />
        <div class="picker-loader__bar flex-1" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alt: {
      type: Boolean,
    },
    divider: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-loader {
  @apply flex sm:w-[236px] md:w-full flex-1 animate-pulse;

  &--divider {
    @apply border-b border-gray-300 pb-5 sm:order-3 sm:border-b-0 sm:pb-0 sm:pr-4 md:pr-7 lg:border-r;
  }

  &__bar {
    @apply h-4 rounded-sm bg-[#F5F5F5];
  }
}
</style>
